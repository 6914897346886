import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'gatsby'

import Panel from './Panel'

import Footer from '../Footer'
import Navigation from '../Navigation'

import '../../styles/field-brand.scss'

const siteCode = process.env.GATSBY_SITE_CODE || 'fi'

const navItems = {
  'fi': [
    {
      exact: 'true',
      path: '/',
      title: 'Home'
    },
    {
      path: '/about/',
      title: 'About'
    },
    {
      path: '/team/',
      title: 'Team'
    },
    {
      path: '/news/',
      title: 'News'
    },
    {
      path: '/contact/',
      title: 'Contact'
    }
  ],
  'sl': [
    {
      path: '/signup/',
      title: 'Sign up',
      button: true
    },
    {
      exact: 'true',
      path: '/',
      title: 'Home'
    },
    {
      path: '/our-services/',
      title: 'Our services'
    },
    {
      path: '/about/',
      title: 'About'
    },
    // {
    //   path: '/supplier/',
    //   title: 'Supplier'
    // },
    {
      path: '/careers/',
      title: 'Careers'
    },
    {
      path: '/contact/',
      title: 'Contact'
    },
    {
      path: 'https://shelflife.field.supply',
      title: 'Member login',
      external: true
    }
  ],
  'fs': [
    // {
    //   exact: 'true',
    //   path: '/',
    //   title: 'Home'
    // },
    // {
    //   path: '/about/',
    //   title: 'About'
    // },
    // {
    //   path: '/features/',
    //   title: 'Features'
    // },
    // {
    //   path: '/contact/',
    //   title: 'Contact'
    // }
  ]
}

let pageConfig = {
  linkRenderer: ({ to, className, children }) => <Link to={to} className={className}>{children}</Link>
}

export function configure (config) {
  pageConfig = {
    ...pageConfig,
    ...config
  }
}

const Page = ({
  children,
  footerColor,
  className
}) => (
  <div className={classnames(
    'fb-page',
    `theme-${siteCode}`,
    className
  )}>
    <Navigation
      site={siteCode}
      linkRenderer={(props) => <Link {...props} />}
      items={navItems[siteCode] || []}
    />
    {children}
    <Footer
      color={footerColor}
      site={siteCode}
      linkRenderer={(props) => <Link {...props} />}
      items={navItems[siteCode] || []}
    />
  </div>
)

Page.propTypes = {
  /**
   * The child elements of the Page
   */
  children: PropTypes.node.isRequired,

  /**
   * The classname(s) to apply to the Page
   */
  className: PropTypes.string
}

Page.defaultProps = {
  className: undefined
}

Page.Panel = Panel

export default Page
